body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* font-size: 18px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* *{
  border-top: 1px solid red
} */
.sectionShadow {
  -webkit-box-shadow: inset 0px 11px 5px 0px rgba(0,0,0,0.35);
-moz-box-shadow: inset 0px 11px 5px 0px rgba(0,0,0,0.35);
box-shadow: inset 0px 11px 5px 0px rgba(0,0,0,0.35);
}


.roundedCorners {
  border-radius: 10px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* .shoppingGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
}
 */


 .shoppingGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
}

.shoppingGrid > div {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 300px;
}

/* .shoppingGrid > div::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 80%;
  border-bottom: 1px solid #000;
} */

@media (max-width: 767px)  {
  .shoppingGrid {
    display: grid;
    grid-template-columns: 1fr;
    /* flex-direction: row; */
  }
  .shoppingGrid > div {
    display: block;
    min-height: 100px;
    margin-bottom: 10px;
  }
}